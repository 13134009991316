import React from 'react';
import Logo from '../logo-transparent.png'
import IGIcon from '../ig-icon.svg'
import FBIcon from '../fb-icon.svg'
import WebsiteIcon from '../website-icon.svg'

// Button component
const SocialButton = ({ url, label, iconImage }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '10px auto',
      width: '80%',
      backgroundColor: '#ce7675',
      color: 'white',
      padding: '10px',
      borderRadius: '5px',
      textDecoration: 'none',
      maxWidth: '300px',
    }}
  >
    <img src={iconImage} alt={label} style={{ marginRight: '10px', width: '24px', height: '24px' }} />
    {label}
  </a>
);



const LinksPage = () => {
  return (
    <div style={{ textAlign: 'center', backgroundColor: '#FFFFFF', minHeight: '100vh', padding: '20px' }}>
      {/* Hero Logo */}
      <div style={{ marginBottom: '50px' }}>
        <img src={Logo} alt="Hero Logo" style={{ maxWidth: '200px', margin: '100px auto' }} />
      </div>

      {/* Buttons */}
      <SocialButton
        url="http://polishwithlove.com"
        label="PolishWithLove.com"
        iconImage={WebsiteIcon} // Update this path
      />
      <SocialButton
        url="https://www.instagram.com/polishwithloveyyc/"
        label="Instagram"
        iconImage={IGIcon} // Update this path
      />
      <SocialButton
        url="http://facebook.com/polishwithloveyyc"
        label="Facebook"
        iconImage={FBIcon} // Update this path
      />
    </div>
  );
};

export default LinksPage;
