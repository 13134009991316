import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Hero from '../components/hero';
import Footer from '../components/footer';
import './contact.css';

const Contact = (props) => {
  return (
    <>
      <div className='home-container'>
        <Helmet>
          <title>Contact - Polish With Love</title>
          <meta property='og:title' content='Contact - Polish With Love' />
        </Helmet>
        <Header />
        <Hero text='Booking Inquiries' isHome={false} />
        <div className='email-text'>
          For Booking Inquiries, Please Email:<br /><br /><a href="mailto:PolishWithLoveCleaning@hotmail.com">PolishWithLoveCleaning@hotmail.com</a>
        </div>
      </div>
      <div className='services-footer'>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
