import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Hero from '../components/hero';
import './portfolio.css';
import Footer from '../components/footer'
import Gallery from 'react-photo-gallery';

import IMG1 from './photos/1.jpeg';
import IMG2 from './photos/2.jpeg';
import IMG3 from './photos/3.jpeg';
import IMG4 from './photos/4.jpeg';
import IMG5 from './photos/5.jpeg';
import IMG6 from './photos/6.jpeg';
import IMG7 from './photos/7.jpeg';
import IMG8 from './photos/8.jpeg';
import IMG9 from './photos/9.jpeg';
import IMG10 from './photos/10.jpeg';
import IMG11 from './photos/11.jpeg';
import IMG12 from './photos/12.jpeg';
import IMG13 from './photos/13.jpeg';
import IMG14 from './photos/14.jpeg';
import IMG15 from './photos/15.jpeg';
import IMG16 from './photos/16.jpeg';
import IMG17 from './photos/17.jpeg';
import IMG18 from './photos/18.jpeg';
import IMG19 from './photos/19.jpeg';
import IMG20 from './photos/20.jpeg';
import IMG21 from './photos/21.jpeg';
import IMG22 from './photos/22.jpeg';
import IMG23 from './photos/23.jpeg';
import IMG24 from './photos/24.jpeg';
import IMG25 from './photos/25.jpeg';
import IMG26 from './photos/26.jpeg';
import IMG27 from './photos/27.jpeg';
import IMG28 from './photos/28.jpeg';
import IMG29 from './photos/29.jpeg';
import IMG30 from './photos/30.jpeg';
import IMG31 from './photos/31.jpeg';
import IMG32 from './photos/32.jpeg';
import IMG33 from './photos/33.jpeg';
import IMG34 from './photos/34.jpeg';
import IMG35 from './photos/35.jpeg';
import IMG36 from './photos/36.jpeg';
import IMG37 from './photos/37.jpeg';
import IMG38 from './photos/38.jpeg';
import IMG39 from './photos/39.jpeg';
import IMG40 from './photos/40.jpeg';
import IMG41 from './photos/41.jpeg';
import IMG42 from './photos/42.jpeg';
import IMG43 from './photos/43.jpeg';
import IMG44 from './photos/44.jpeg';
import IMG45 from './photos/45.jpeg';
import IMG46 from './photos/46.jpeg';
import IMG47 from './photos/47.jpeg';
import IMG48 from './photos/48.jpeg';
import IMG49 from './photos/49.jpeg';
import IMG50 from './photos/50.jpeg';
import IMG51 from './photos/51.jpeg';
import IMG52 from './photos/52.jpeg';
import IMG53 from './photos/53.png';
import IMG54 from './photos/54.jpeg';
import IMG55 from './photos/55.jpeg';
import IMG56 from './photos/56.jpeg';
import IMG57 from './photos/57.jpeg';
import IMG58 from './photos/58.jpeg';
import IMG59 from './photos/59.jpeg';
import IMG60 from './photos/60.jpeg';
import IMG61 from './photos/61.jpeg';
import IMG62 from './photos/62.jpeg';
import IMG63 from './photos/63.jpeg';
import IMG64 from './photos/64.jpeg';
import IMG65 from './photos/65.jpeg';
import IMG66 from './photos/66.jpeg';
import IMG67 from './photos/67.jpeg';
import IMG68 from './photos/68.jpeg';
import IMG69 from './photos/69.jpeg';
import IMG70 from './photos/70.jpeg';
import IMG71 from './photos/71.jpeg';
import IMG72 from './photos/72.jpeg';
import IMG73 from './photos/73.jpeg';
import IMG75 from './photos/75.jpeg';
import IMG76 from './photos/76.jpeg';
import IMG77 from './photos/77.jpeg';
import IMG78 from './photos/78.jpeg';
import IMG79 from './photos/79.jpeg';
import IMG81 from './photos/81.jpeg';
import IMG82 from './photos/82.jpeg';
import IMG83 from './photos/83.jpeg';
import IMG84 from './photos/84.jpeg';
import IMG85 from './photos/85.jpeg';
import IMG91 from './photos/91.jpeg';
import IMG92 from './photos/92.jpeg';
import IMG93 from './photos/93.jpeg';
import IMG94 from './photos/94.jpeg';
import IMG95 from './photos/95.jpeg';
import IMG96 from './photos/96.jpeg';
import IMG97 from './photos/97.jpeg';
import IMG98 from './photos/98.jpeg';
import IMG99 from './photos/99.jpeg';
import IMG100 from './photos/100.jpeg';
import IMG101 from './photos/101.jpeg';
import IMG102 from './photos/102.jpeg';
import IMG103 from './photos/103.jpeg';

const Portfolio = (props) => {
  const photos = [
    {
      src: IMG1,
      width: 2,
      height: 3,
    },
    {
      src: IMG2,
      width: 2,
      height: 3,
    },
    {
      src: IMG3,
      width: 2,
      height: 3,
    },
    {
      src: IMG4,
      width: 2,
      height: 3,
    },
    {
      src: IMG5,
      width: 2,
      height: 3,
    },
    {
      src: IMG6,
      width: 2,
      height: 3,
    },
    {
      src: IMG7,
      width: 2,
      height: 3,
    },
    {
      src: IMG8,
      width: 2,
      height: 3,
    },
    {
      src: IMG9,
      width: 2,
      height: 3,
    },
    {
      src: IMG10,
      width: 2,
      height: 3,
    },
    {
      src: IMG11,
      width: 2,
      height: 3,
    },
    {
      src: IMG12,
      width: 2,
      height: 3,
    },
    {
      src: IMG13,
      width: 2,
      height: 3,
    },
    {
      src: IMG14,
      width: 2,
      height: 3,
    },
    {
      src: IMG15,
      width: 2,
      height: 3,
    },
    {
      src: IMG16,
      width: 2,
      height: 3,
    },
    {
      src: IMG17,
      width: 2,
      height: 3,
    },
    {
      src: IMG18,
      width: 2,
      height: 3,
    },
    {
      src: IMG19,
      width: 2,
      height: 3,
    },
    {
      src: IMG20,
      width: 2,
      height: 3,
    },
    {
      src: IMG21,
      width: 2,
      height: 3,
    },
    {
      src: IMG22,
      width: 2,
      height: 3,
    },
    {
      src: IMG23,
      width: 2,
      height: 3,
    },
    {
      src: IMG24,
      width: 2,
      height: 3,
    },
    {
      src: IMG25,
      width: 2,
      height: 3,
    },
    {
      src: IMG26,
      width: 2,
      height: 3,
    },
    {
      src: IMG27,
      width: 2,
      height: 3,
    },
    {
      src: IMG28,
      width: 2,
      height: 3,
    },
    {
      src: IMG29,
      width: 2,
      height: 3,
    },
    {
      src: IMG30,
      width: 2,
      height: 3,
    },
    {
      src: IMG31,
      width: 2,
      height: 3,
    },
    {
      src: IMG32,
      width: 2,
      height: 3,
    },
    {
      src: IMG33,
      width: 2,
      height: 3,
    },
    {
      src: IMG34,
      width: 2,
      height: 3,
    },
    {
      src: IMG35,
      width: 2,
      height: 3,
    },
    {
      src: IMG36,
      width: 2,
      height: 3,
    },
    {
      src: IMG37,
      width: 2,
      height: 3,
    },
    {
      src: IMG38,
      width: 2,
      height: 3,
    },
    {
      src: IMG39,
      width: 2,
      height: 3,
    },
    {
      src: IMG40,
      width: 2,
      height: 3,
    },
    {
      src: IMG41,
      width: 2,
      height: 3,
    },
    {
      src: IMG42,
      width: 2,
      height: 3,
    },
    {
      src: IMG43,
      width: 2,
      height: 3,
    },
    {
      src: IMG44,
      width: 2,
      height: 3,
    },
    {
      src: IMG45,
      width: 2,
      height: 3,
    },
    {
      src: IMG46,
      width: 2,
      height: 3,
    },
    {
      src: IMG47,
      width: 2,
      height: 3,
    },
    {
      src: IMG48,
      width: 2,
      height: 3,
    },
    {
      src: IMG49,
      width: 2,
      height: 3,
    },
    {
      src: IMG50,
      width: 2,
      height: 3,
    },
    {
      src: IMG51,
      width: 2,
      height: 3,
    },
    {
      src: IMG52,
      width: 2,
      height: 3,
    },
    {
      src: IMG53,
      width: 2,
      height: 3,
    },
    {
      src: IMG54,
      width: 2,
      height: 3,
    },
    {
      src: IMG55,
      width: 2,
      height: 3,
    },
    {
      src: IMG56,
      width: 2,
      height: 3,
    },
    {
      src: IMG57,
      width: 2,
      height: 3,
    },
    {
      src: IMG58,
      width: 2,
      height: 3,
    },
    {
      src: IMG59,
      width: 2,
      height: 3,
    },
    {
      src: IMG60,
      width: 2,
      height: 3,
    },
    {
      src: IMG61,
      width: 2,
      height: 3,
    },
    {
      src: IMG62,
      width: 2,
      height: 3,
    },
    {
      src: IMG63,
      width: 2,
      height: 3,
    },
    {
      src: IMG64,
      width: 2,
      height: 3,
    },
    {
      src: IMG65,
      width: 2,
      height: 3,
    },
    {
      src: IMG66,
      width: 2,
      height: 3,
    },
    {
      src: IMG67,
      width: 2,
      height: 3,
    },
    {
      src: IMG68,
      width: 2,
      height: 3,
    },
    {
      src: IMG69,
      width: 2,
      height: 3,
    },
    {
      src: IMG70,
      width: 2,
      height: 3,
    },
    {
      src: IMG71,
      width: 2,
      height: 3,
    },
    {
      src: IMG72,
      width: 2,
      height: 3,
    },
    {
      src: IMG73,
      width: 2,
      height: 3,
    },
    {
      src: IMG75,
      width: 2,
      height: 3,
    },
    {
      src: IMG76,
      width: 2,
      height: 3,
    },
    {
      src: IMG77,
      width: 2,
      height: 3,
    },
    {
      src: IMG78,
      width: 2,
      height: 3,
    },
    {
      src: IMG79,
      width: 2,
      height: 3,
    },
    {
      src: IMG81,
      width: 2,
      height: 3,
    },
    {
      src: IMG82,
      width: 2,
      height: 3,
    },
    {
      src: IMG83,
      width: 2,
      height: 3,
    },
    {
      src: IMG84,
      width: 2,
      height: 3,
    },
    {
      src: IMG85,
      width: 2,
      height: 3,
    },
    {
      src: IMG91,
      width: 2,
      height: 3,
    },
    {
      src: IMG92,
      width: 2,
      height: 3,
    },
    {
      src: IMG93,
      width: 2,
      height: 3,
    },
    {
      src: IMG94,
      width: 2,
      height: 3,
    },
    {
      src: IMG95,
      width: 2,
      height: 3,
    },
    {
      src: IMG96,
      width: 2,
      height: 3,
    },
    {
      src: IMG97,
      width: 2,
      height: 3,
    },
    {
      src: IMG98,
      width: 2,
      height: 3,
    },
    {
      src: IMG99,
      width: 2,
      height: 3,
    },
    {
      src: IMG100,
      width: 2,
      height: 3,
    },
    {
      src: IMG101,
      width: 2,
      height: 3,
    },
    {
      src: IMG102,
      width: 2,
      height: 3,
    },
    {
      src: IMG103,
      width: 2,
      height: 3,
    },
  ];

  return (
    <>
    <div className='home-container'>
      <Helmet>
        <title>Portfolio - Polish With Love</title>
        <meta property='og:title' content='Portfolio - Polish With Love' />
      </Helmet>
      <Header />
      <Hero text='Portfolio' isHome={false} />
      <div className='services-listing'>
        <Gallery photos={photos} />
      </div>
    </div>
    <div className='services-footer'>
        <Footer />
      </div>
    </>
  );
};

export default Portfolio;
