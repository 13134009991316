import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Hero from '../components/hero';
import Footer from '../components/footer';
import './services.css'; // Assuming the Services page can reuse the home page CSS for consistency

const Services = (props) => {
  return (
    <>
      <div className='home-container'>
        <Helmet>
          <title>Services - Polish With Love</title>
          <meta property='og:title' content='Services - Polish With Love' />
        </Helmet>
        <Header />
        <Hero text='Services & Pricing' isHome={false} />
        <div className='services-listing'>
          <div className='listingContainer'>
            <div className='services-item'>
              <h2 className='service-title heading2'>
                Standard Clean - $35 Per Hour (+$5 Outside Calgary)
              </h2>
              <p className='service-description bodySmall'>
                A thorough cleaning of your space, perfect for regular maintenance.
              </p>
            </div>
            <div className='services-item'>
              <h2 className='service-title heading2'>
                Deep Clean - $45 Per Hour (+$5 Outside Calgary)
              </h2>
              <p className='service-description bodySmall'>
                An extensive cleaning focusing on deep grime and dirt. Deep Clean Minimum 4 Hour
                Charge.
              </p>
            </div>
            <div className='services-item'>
              <h2 className='service-title heading2'>Move-in/Move-out Clean - $50 Per Hour</h2>
              <p className='service-description bodySmall'>
                Ideal for moving in or out, ensuring your space is spotless. 4 Hour Minimum Charge.
              </p>
            </div>
            <div className='services-item'>
              <h2 className='service-title heading2'>Additional Info:</h2>
              <ul className='service-inclusions' style={{ listStyleType: 'circle' }}>
                <li>Eco-Friendly Cleaning Supplies Upon Request</li>
                <li>24 Hour Notice of Cancellation or Reschedule Required</li>
                <li>35% Charge For Less Than 24 Hour Rescheduling or Cancellation Notice</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='services-footer'>
        <Footer />
      </div>
    </>
  );
};

export default Services;
