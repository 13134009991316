import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './hero.css';
import heroImage from '../header-background.png';

const Hero = ({ text, isHome }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className='hero'>
      <img src={heroImage} alt='Polish With Love Background' className='hero-img' />
      <div className='hero-overlay'></div>
      <div className='hero-text'>
        <h1 className='hero-heading heading1'>{text}</h1>
        {isHome && (
          <div className='hero-sub-container'>
            <div className='hero-sub-heading'>
              <span>Professional Cleaning Services in Calgary</span>
            </div>
            <div className='hero-btn-group'>
              <Link to='/contact' className='nav-link'>
                <button className='buttonFilled'>Get a Free Quote</button>
              </Link>
              <Link to='/services' className='nav-link'>
                <button className='buttonFlat'>Learn More →</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hero;
