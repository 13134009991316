import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css';
import logo from '../logo-transparent.png';

const Header = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className='home-header'>
      <header data-thq='thq-navbar' className='navbarContainer home-navbar-interactive'>
        <img src={logo} className='header-logo-image' alt='Polish With Love' />
        <div data-thq='thq-navbar-nav' className='home-desktop-menu'>
          <nav className='home-links'>
            <Link to='/' className='nav-link'>
              Home
            </Link>
            <Link to='/services' className='nav-link'>
              Services
            </Link>
            <Link to='/portfolio' className='nav-link'>
              Portfolio
            </Link>
            <Link to='/contact' className='nav-link'>
              Contact
            </Link>
          </nav>
        </div>
        <div data-thq='thq-burger-menu' className='home-burger-menu' onClick={toggleMenu}>
          <svg
            viewBox='0 0 1024 1024'
            className='home-icon'
            style={{ fill: 'var(--dl-color-custom-neutral-dark)' }}
          >
            <path d='M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z'></path>
          </svg>
        </div>

        {isMenuOpen && (
          <div className='home-mobile-menu1 mobileMenu'>
            <nav className='home-links1'>
              <Link to='/' className='nav-link'>
                Home
              </Link>
              <Link to='/services' className='nav-link'>
                Services
              </Link>
              <Link to='/portfolio' className='nav-link'>
                Portfolio
              </Link>
              <Link to='/contact' className='nav-link'>
                Contact
              </Link>
            </nav>
          </div>
        )}
      </header>
    </div>
  );
};

export default Header;
